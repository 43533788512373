<template>
  <router-view/>
</template>

<script setup>

</script>

<style lang="scss" setup>

</style>
