import request from '@/utils/request'

/**
 * 获取栏目信息
 */
export const getCategoryList = data => {
    return request({
        url: '/api/category/list',
        params: data
    })
}

/**
 * 获取当前栏目信息
 */
export const getCategoryListId = id => {
    return request({
        url: '/api/category/list/' + id
    })
}

/**
 * 获取列表数据(categoryId)
 */
export const getArticleList = data => {
    return request({
        url: '/api/article/list',
        params: data
    })
}

/**
 * 获取列表详情数据
 */
export const getArticleInfo = data => {
    return request({
        url: '/api/article/info',
        params: data
    })
}

/**
 * 获取友情链接数据
 */
export const friendlinkList = data => {
    return request({
        url: '/api/friendlink/list',
        params: data
    })
}

/**
 * 搜索
 */
export const getArticleListSearch = (content,data) => {
    return request({
        url: '/api/search/' + content,
        params: data
    })
}

/**
 * 轮播图
 */
export const getLinkSlideshow = data => {
    return request({
        url: '/api/hot/list',
        params: data
    })
}
