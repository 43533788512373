import axios from 'axios'
import { ElMessage } from 'element-plus'
// import store from '@/store'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 接口地址
    timeout: 30000 // 30秒后超时
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这个位置需要统一的去注入token
        // if (store.getters.token) {
        //     // 如果token存在 注入token
        //     config.headers.Authorization = `Bearer ${store.getters.token}`
        // }
        // // 配置 icode
        // config.headers.icode = '0CD2D67DC9EDB9F7'
        return config // 必须返回配置
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const { code, msg } = response.data
        //   要根据success的成功与否决定下面的操作
        if (code === 0) {
            return response.data
        } else if (code === 500){
            return Promise.reject(new Error('code:500'))
        } else {
            // 业务错误
            // ElMessage.error(msg) // 提示错误消息
            return Promise.reject(new Error(msg))
        }
    },
    error => {
        // // 处理 token 超时问题
        // if (error.response?.data?.code === 401) {
        //     store.dispatch('user/logout')
        // }
        // ElMessage.error(error.message) // 提示错误信息
        return Promise.reject(error)
    }
)

export default service
