<template>
  <!-- 友情链接 -->
  <div class="blogrollWai">
    <div class="blogroll">
      <div class="blogrollLink">
        <span>友情链接</span>
      </div>
      <div :key="index" v-for="(item,index) in options">
        <el-select class="m-2" :placeholder="item[0].typeName" size="Select">
          <el-option
              v-for="item1 in item"
              :key="item1.value"
              :label="item1.label"
              :value="item1.value"
              @click="handleClickOption(item1)"
          />
        </el-select>
      </div>
    </div>
  </div>
  <!-- 页脚 -->
  <div id="FootPage">
    <div class="FootPageOuter">
      <!-- 友情链接 -->
<!--      <div class="blogroll">-->
<!--        <div class="blogrollLink">-->
<!--          <span>友情链接</span>-->
<!--        </div>-->
<!--        <div :key="index" v-for="(item,index) in options">-->
<!--          <el-select v-model="selectValue[index]" class="m-2" :placeholder="item[0].typeName" size="large">-->
<!--            <el-option-->
<!--                v-for="item1 in item"-->
<!--                :key="item1.value"-->
<!--                :label="item1.label"-->
<!--                :value="item1.value"-->
<!--                @click="handleClickOption(item1)"-->
<!--            />-->
<!--          </el-select>-->
<!--        </div>-->
<!--      </div>-->
      <!-- 页脚信息 -->
      <div class="FooterInformation">
        <!-- 页脚网站链接 -->
<!--        <div class="FooterInformationUrl">-->
<!--          <ul>-->
<!--            <li><a href="">网站首页</a><span>|</span></li>-->
<!--            <li><a href="http://223.244.35.4:81/#/ListForm/114/1535">关于我们</a><span>|</span></li>-->
<!--            <li><a href="http://223.244.35.4:81/#/ListForm/121/1537">联系我们</a></li>-->
<!--          </ul>-->
<!--        </div>-->
        <!-- 网站地图 -->
        <div class="blogrolls">
          <template v-for="(item, index) in store.getters.headCategory">
            <ul v-if="item.existSubMenu" :key="index">
              <p>{{item.name}}</p>
              <li v-for="(a, num) in item.children" :key="num" @click="handleSelect(a)">{{a.name}}</li>
            </ul>
          </template>
        </div>
        <!-- 网站其他信息 -->
        <div class="FooterInformationOther">
          <div><span>{{object.message1}}</span></div>
          <div>
            <span>网站备案号：<a target="_blank" href="https://beian.miit.gov.cn/">{{object.message2}}</a></span>
            <span>电话：{{object.message3}}</span>
            <span>传真：{{object.message4}}</span>
          </div>
          <div><span>技术支持：{{object.message5}}</span></div>
          <div>
            <span>网站管理：{{object.message6}}</span>
            <span>地址：{{object.message7}}</span>
          </div>
          <div class="QRcode">
            <div><img src="../../assets/imgs/QRcode.png"></div>
            <div>
              <div>扫码关注</div>
              <div>微信公众号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import {friendlinkList} from "@/api/manage";
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";

const router = useRouter()
const store = useStore()
const route = useRoute()
const selectValue = ref([])
const options = ref([])
const object = {
  message1: 'Copyright2023 @ www.hflsjt.com All Rights Reserved.合肥市粮食集团有限公司]版权所有',
  message2: '皖ICP备2023011291号-1',
  message3: '0551-63617907',
  message4: '0551-63617907',
  message5: '合肥市粮食集团有限公司行政人事部',
  message6: '合肥市粮食集团有限公司',
  message7: '合肥市蜀山区环湖东路29号',
}

watch(route, async (val) => {
  // 当前栏目列表
  const optionsData = await friendlinkList()
  let arrayList = [[],[],[]]
  for (let i=0; i<optionsData.data.length; i++){
    let object = {}
    if (optionsData.data[i].linkType === '1'){
      object.value = optionsData.data[i].linkName
      object.label = optionsData.data[i].linkName
      object.linkUrl = optionsData.data[i].linkUrl
      object.typeName = '国家部门网站'
      arrayList[0].push(object)
    }else if (optionsData.data[i].linkType === '2'){
      object.value = optionsData.data[i].linkName
      object.label = optionsData.data[i].linkName
      object.linkUrl = optionsData.data[i].linkUrl
      object.typeName = '省市部门网站'
      arrayList[1].push(object)
    }else if (optionsData.data[i].linkType === '3'){
      object.value = optionsData.data[i].linkName
      object.label = optionsData.data[i].linkName
      object.linkUrl = optionsData.data[i].linkUrl
      object.typeName = '知名媒体'
      arrayList[2].push(object)
    }
  }
  options.value = arrayList
},{immediate: true})

//友情链接跳转
const handleClickOption = (value) => {
  // window.location.href = value.linkUrl
  window.open(value.linkUrl, "_blank");
}

// 菜单链接跳转
const handleSelect = (value) => {
  const index = value.id
  if (index !== '1'){
    window.scrollTo(0,0);
    router.push(`/ListForm/${index}`)
  }else {
    window.scrollTo(0,0);
    router.push(`/home`)
  }
}
</script>

<style lang='scss' scoped>
#FootPage{
  width: 100%;
  //height: 440px;
  //height: 240px;
  background-image: url("@/assets/imgs/foot.png");
  background-size: 100% 100%;
  .FootPageOuter{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 30px 0 50px 25px;
    .blogroll{
      text-align: left;
      margin-bottom: 60px;
      >div{
        margin-right: 20px;
        display: inline-block;
        >span{
          color: white;
          font-size: 18px;
          font-weight: bolder;
          margin-left: 20px;
        }
      }
    }
    .FooterInformation{
      color: white;
      font-size: 18px;
      font-weight: bolder;
      .blogrolls{
        text-align: left;
        display: flex;
        ul{
          font-size: 14px;
          font-weight: normal;
          display: inline-block;
          padding: 0 20px;
          color: #CDD8F1;
          text-align: center;
          margin-right: 145px;
          li{
            padding: 4px 0;
            cursor: pointer;
          }
          p{
            font-size: 18px;
            color: white;
            padding-bottom: 10px;
          }
        }
        ul:last-child{
          margin-right: 0;
        }
      }
      .FooterInformationUrl{
        padding-bottom: 20px;
        border-bottom: 2px solid #0043B2;
        >ul{
          padding-left: 20px;
          text-align: left;
          li{
            cursor: pointer;
            display: inline-block;
            span{
              display: inline-block;
              padding: 0 10px;
              font-size: 19px;
            }
          }
        }
      }
      .FooterInformationOther{
        font-size: 15px;
        font-weight: bold;
        padding: 25px 0 0 20px;
        text-align: left;
        position: relative;
        color: #CDD8F1;
        >div{
          margin-bottom: 10px;
          span{
            margin-right: 20px;
            display: inline-block;
          }
        }
        .QRcode{
          position: absolute;
          right: 42px;
          top: 25px;
          overflow: hidden;
          text-align: center;
        }
      }
    }
  }
}

.blogrollWai{
  background-color: #C01925;
  padding: 10px 0;
  .blogroll{
    width: 1200px;
    text-align: left;
    margin: 0 auto;
    padding-left: 25px;
    >div{
      margin-right: 20px;
      display: inline-block;
      >span{
        color: white;
        font-size: 18px;
        font-weight: bolder;
        margin-left: 20px;
      }
    }
    >div:last-child{
      margin-right: 0;
    }
    :deep .el-input__wrapper{
      background: #AC1621;
      box-shadow: 0 0 0 0;
      .el-input__inner{
        color: white;
      }
    }
    :deep .el-select{
      width: 300px;
      margin-right: 40px;
    }
  }
}

.blogrollLink{
  vertical-align: middle;
}
</style>
