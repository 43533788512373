import { createStore } from 'vuex'
import app from './modules/app'


export default createStore({
  state: {
  },
  getters: {
    language: state => state.app.language,
    headCategory: state => state.app.headCategory,
    headCategorySplit: state => state.app.headCategorySplit,
    headCategorySplitIng: state => state.app.headCategorySplitIng,
    homePageData: state => state.app.homePageData,
    inputSearch: state => state.app.inputSearch,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
  }
})
