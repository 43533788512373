import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/Home',
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "header" */ '../views/build/Home.vue'),
      },
      {
        path: '/ListForm',
        name: 'ListForm',
        redirect: '/ListForm',
        children: [
          {
            path: '/ListForm/:id',
            name: 'ListForm',
            component: () => import(/* webpackChunkName: "Footer" */ '../views/build/ListForm.vue'),
          },
          {
            path: '/ListForm/:id/:num',
            name: 'ListForm2',
            component: () => import(/* webpackChunkName: "Footer" */ '../views/build/ListForm.vue'),
          }
        ]
      },
      {
        path: '/Search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "header" */ '../views/build/Search.vue'),
      },
      // {
      //   path: '/content',
      //   name: 'content',
      //   component: () => import(/* webpackChunkName: "header" */ '../views/build/content.vue'),
      // }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
