import { LANG } from '@/constant'
import {getItem, getItemSession} from '@/utils/storage'
import {ref} from "vue";
// import router from '@/router'

export default {
    namespaced: true,
    state: () => ({
        language: getItem(LANG) || 'zh', // 语言
        headCategory: getItemSession('headCategory') || [], // 栏目分类
        headCategorySplit: getItemSession('headCategorySplit') || [], // 整理后栏目分类
        headCategorySplitIng: [], // 当前栏目分类
        homePageData: [], //首页列表数据缓存
        inputSearch: '' //搜索后返回数据
    }),
    mutations: {
        // 格式化栏目分类
        setHeadCategory(state,data){
            state.headCategory = data
            setHeadCategoryF(data)
            function setHeadCategoryF(aaa){
                for (let a = 0; a < aaa.length; a++){
                    let category = {}
                    category.id = aaa[a].id
                    category.pId = aaa[a].pId
                    category.name = aaa[a].name
                    category.existSubMenu = aaa[a].existSubMenu
                    state.headCategorySplit.push(category)
                    if (aaa[a].existSubMenu) {
                        setHeadCategoryF(aaa[a].children)
                    }
                }
            }
        },
        // 栏目分类信息查询
        ColumnInformationQuery(state,id){
            const headCategorySplitLength = state.headCategorySplit.length
            for(let a = 0; a < headCategorySplitLength; a++){
                if(state.headCategorySplit[a].id === Number(id)) {
                    state.headCategorySplitIng = state.headCategorySplit[a]
                }
            }
        },
        //首页列表数据
        homePageDataFun(state,data){
            state.homePageData = data
        },
        //搜索内容
        searchDataFun(state,data){
            state.inputSearch = data
        },
    },
    actions: {}
}
