<template>
  <div id="header" class="farthest">
    <div>
      <div><span>{{dateTime}}</span></div>
      <div class="referenceWai">
        <el-popover
            placement="bottom"
            title=""
            :width="150"
            trigger="hover"
            content=""
            popper-class="referenceQRcode"
        >
          <div class="referenceQRcode"><img src="../../assets/imgs/QRcode.png" alt=""></div>
          <template #reference>
            <div class="reference">关注微信公众号</div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
  <div class="farthest_middle">
    <div>
      <!--公司名称logo-->
      <div></div>
      <!--搜索-->
      <div class="search">
        <div class="mt-4">
          <el-input
              v-model="inputSearch"
              placeholder="请输入"
              class="input-with-select"
          >
          </el-input>
          <div class="searchLogo" @click="handleClickSearch"></div>
        </div>
      </div>
    </div>
  </div>
  <!--列表-->
  <div class="farthest_foot">
    <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#004DCA"
        text-color="#fff"
        active-text-color="#FFFFFF"
        @select="handleSelect"
    >
      <template v-for="(item, index) in titles">
        <el-menu-item :index="String(item.id)" :key="index" v-if="!item.existSubMenu">{{item.name}}</el-menu-item>
        <el-sub-menu :index="String(item.id)" :key="index" v-if="item.existSubMenu">
          <template #title>{{item.name}}</template>
          <el-menu-item :index="String(item1.id)" :key="num" v-for="(item1, num) in item.children">{{item1.name}}</el-menu-item>
        </el-sub-menu>
      </template>
    </el-menu>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import {getArticleList, getArticleListSearch, getCategoryList, getCategoryListId} from "@/api/manage";
import { useStore } from 'vuex'
import {getItemSession, setItemSession} from '@/utils/storage'

const store = useStore()
const router = useRouter()
const route = useRoute()
const dateTime = ref('')
const inputSearch = ref('') //搜索内容
const colorDeepen = ref({Active: 0})
const titles = ref([]) //栏目分类
const activeIndex2 = ref('') // 当前栏目

// 菜单
const handleSelect = (index,indexPath,routeResult) => {
  if (index !== '1'){
    window.scrollTo(0,0);
    router.push(`/ListForm/${index}`)
  }else {
    window.scrollTo(0,0);
    router.push(`/home`)
    activeIndex2.value = '1'
  }
}

// 监听路由跳转
watch(route, (val) => {
  const routeParamsId = route.params.id
  if(!routeParamsId){
    activeIndex2.value = '1'
  }else {
    activeIndex2.value = routeParamsId
  }
})

// 时间日期即时显示
const TimeInterval = setInterval(() => {
  const date = new Date();
  const getFullYear = date.getFullYear(); //获取完整的年份(4位)
  const getMonth = date.getMonth()+1; //获取当前月份(0-11,0代表1月)
  const getDate = date.getDate(); //获取当前日(1-31)
  const getHours = date.getHours(); //获取当前小时数(0-23)
  let getMinutes = date.getMinutes(); //获取当前分钟数(0-59)
  let getSeconds = date.getSeconds(); //获取当前秒数(0-59)
  let getDay = date.getDay(); //获取当前星期X(0-6,0代表星期天)
  if (getMinutes < 10 && getSeconds < 10){
    getMinutes = '0' + getMinutes
    getSeconds = '0' + getSeconds
  }else if(getMinutes < 10){
    getMinutes = '0' + getMinutes
  }else if(getSeconds < 10){
    getSeconds = '0' + getSeconds
  }
  switch (getDay)
  {
    case 0: getDay="星期日";
      break;
    case 1: getDay="星期一";
      break;
    case 2: getDay="星期二";
      break;
    case 3: getDay="星期三";
      break;
    case 4: getDay="星期四";
      break;
    case 5: getDay="星期五";
      break;
    case 6: getDay ="星期六";
      break;
  }
  dateTime.value = getFullYear + '年' + getMonth + '月' + getDate + '日' + getHours + ':' + getMinutes + ':' + getSeconds + ' ' + getDay
}, 1000)

// 请求栏目数据
const getListData = async () => {
  const result = await getCategoryList({})
  titles.value = result.data
  if (!getItemSession('headCategorySplitExist')){
    await store.commit('app/setHeadCategory',result.data)
    setItemSession('headCategory', store.getters.headCategory)
    setItemSession('headCategorySplit', store.getters.headCategorySplit)
  }
  setItemSession('headCategorySplitExist', true)
}
getListData()

//搜索
function handleClickSearch() {
  store.commit('app/searchDataFun',inputSearch.value)
  router.push(`/Search`)
}
//搜索(enter键)
document.onkeyup = function(e) {
  // 兼容FF和IE和Opera
  var event = e || window.event;
  var key = event.which || event.keyCode || event.charCode;
  if (key === 13) {
    handleClickSearch()
  }
}
</script>

<style lang='scss' scoped>
.farthest{
  width: 100%;
  height: 30px;
  background-color: #F0F0F0;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  >div{
    width: 1200px;
    display: inline-block;
    padding-left: 30px;
    color: #A1A1A1;
    div:first-child{
      float: left;
    }
    .referenceWai{
      float: right;
    }
    .reference{
      padding-left: 25px;
      background-image: url("@/assets/imgs/weixin.png");
      background-repeat: no-repeat;
      background-position-y: center;
      cursor:pointer;
    }
  }
}

.farthest_middle{
  width: 100%;
  height: 145px;
  text-align: center;
  line-height: 100px;
  font-size: 14px;
  background-image: url("@/assets/imgs/top_groupback.jpg");
  background-repeat: no-repeat;
  background-size:100% 100%;
  //background-position-y: center;
  >div{
    width: 1200px;
    height: 100%;
    overflow: hidden;
    display: inline-block;
    >div:first-child{
      float: left;
      width: 60%;
      min-width: 600px;
      height: 100%;
      background-image: url("@/assets/imgs/logoTitle2.png");
      background-repeat: no-repeat;
      background-position-x: 10px;
      background-position-y: center;
    }
    .search{
      width: 40%;
      min-width: 400px;
      height: 100%;
      float: right;
      display: inline-block;
      position: relative;
      line-height: 150px;
      text-align: right;
      .el-input{
        width: 350px;
        --el-input-border-color: #004DCA;
        --el-input-hover-border-color: #004DCA;
        --el-input-focus-border-color: #004DCA;
        .el-input__wrapper{
          padding-right: 10px;
        }
      }
      /* .el-input[data-v-19c98e7a]{
        height: 41px;
        border-radius: 20px;
        margin-top: 10px;
      } */
      .input-with-select{
        height: 41px;
        border-radius: 20px;
        margin-top: 10px;
      }
      :deep .el-input-group__append{
        border-radius: 20px;
        background-color: white;
        opacity: 0;
      }
      :deep .el-input__wrapper{
        padding: 0 20px;
        border-radius: 20px;
      }
      .mt-4{
        position: relative;
        .searchLogo{
          width: 39px;
          height: 40px;
          border-radius: 50%;
          background-image: url("@/assets/imgs/search144.png");
          background-size: cover;
          display: inline-block;
          position: absolute;
          top: 61px;
          right: 2px;
          cursor: pointer;
        }
      }
    }
  }
}

.farthest_foot{
  width: 100%;
  background-color: #004DCA;
  ul{
    width: 1080px;
    margin: 0 auto;
    height: 75px;
    font-size: 23px;
    color: white;
    line-height: 75px;
    --el-menu-hover-bg-color: rgb(0, 77, 202) !important;
    li{
      line-height: 75px;
      display: inline-block;
      padding: 0 30px;
      cursor: pointer;
      user-select: none;
    }
    .colorDeepen{
      background-color: #001E59;
    }
  }
}

:deep .el-input__wrapper{
  border-radius: 20px;
}
:deep .el-input__suffix{
  padding-right: 10px;
  cursor: pointer;
}
.referenceQRcode{
  text-align: center;
}

.el-menu--horizontal>:deep .el-menu-item{
  height: 75px;
  font-size: 20px !important;
}
.el-menu--horizontal>:deep .el-sub-menu>div{
  height: 75px;
  font-size: 20px !important;
}
.el-menu--horizontal>:deep .el-sub-menu{
  padding-right: 0px;
}
.el-menu{
  border-right: 0;
  border-bottom: 0;
}

//.el-menu--horizontal>.el-menu-item.is-active{
//  border-bottom: none !important;
//}
//
//.el-menu--horizontal>.el-sub-menu .el-sub-menu__title{
//  border-bottom: none !important;
//}
</style>
